import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar2"; // Import the Navbar component
import "./App.css"; // Import global styles
import Scoreboard from "./Scoreboard"; // Import the Scoreboard component

// Define the teams for each person using full names, with "LA Clippers" for Dakota
const teamsPerPerson = {
  Charlie: [
    "Oklahoma City Thunder",
    "Memphis Grizzlies",
    "Philadelphia 76ers",
    "Los Angeles Lakers",
    "Chicago Bulls",
    "Brooklyn Nets",
  ],
  Noah: [
    "Denver Nuggets",
    "Dallas Mavericks",
    "Miami Heat",
    "Sacramento Kings",
    "Charlotte Hornets",
    "Toronto Raptors",
  ],
  Austen: [
    "New York Knicks",
    "Cleveland Cavaliers",
    "Orlando Magic",
    "New Orleans Pelicans",
    "Atlanta Hawks",
    "Detroit Pistons",
  ],
  JR: [
    "Golden State Warriors",
    "Utah Jazz",
    "Milwaukee Bucks",
    "Houston Rockets",
    "Boston Celtics",
    "Washington Wizards",
  ],
  Dakota: [
    "Minnesota Timberwolves",
    "Phoenix Suns",
    "Indiana Pacers",
    "San Antonio Spurs",
    "LA Clippers",
    "Portland Trail Blazers",
  ],
};

// Home component (your original standings page)
const Home = () => {
  const [standings, setStandings] = useState([]);
  const [sortedFriends, setSortedFriends] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchStandings = async () => {
    setLoading(true); // Set loading to true when fetching starts
    const options = {
      method: "GET",
      url: "https://api-nba-v1.p.rapidapi.com/standings",
      params: { league: "standard", season: "2024" },
      headers: {
        "x-rapidapi-host": "api-nba-v1.p.rapidapi.com",
        "x-rapidapi-key": "c2ef872e3emsh085221d8c5b21f3p13749djsn5f472c57f22f",
      },
    };

    try {
      console.log("Making API request...");
      const response = await axios.request(options);
      console.log("API Response:", response.data.response); // Log the API response
      setStandings(response.data.response); // Update standings
    } catch (error) {
      console.error("Error fetching NBA standings", error);
    } finally {
      setLoading(false); // Stop loading once the request is done
    }
  };

  useEffect(() => {
    fetchStandings(); // Call the API on the first render
  }, []);

  // Function to get teams owned by a person
  const getTeamsForPerson = (personTeams) => {
    return standings
      .filter((team) =>
        personTeams.some(
          (personTeam) =>
            personTeam.toLowerCase() === team.team.name.toLowerCase()
        )
      )
      .sort((a, b) => b.win.total - a.win.total); // Sort teams by number of wins
  };

  // Function to calculate total wins, losses, and winning percentage
  const calculateTotalRecord = (personTeams) => {
    const teams = getTeamsForPerson(personTeams);
    let totalWins = 0;
    let totalLosses = 0;

    teams.forEach((team) => {
      totalWins += team.win.total;
      totalLosses += team.loss.total;
    });

    const winPercentage =
      totalWins + totalLosses > 0
        ? ((totalWins / (totalWins + totalLosses)) * 100).toFixed(2)
        : "0.00";

    return { totalWins, totalLosses, winPercentage };
  };

  useEffect(() => {
    if (standings.length > 0) {
      const sorted = Object.keys(teamsPerPerson)
        .map((person) => {
          const { totalWins, totalLosses, winPercentage } =
            calculateTotalRecord(teamsPerPerson[person]);
          return { person, totalWins, totalLosses, winPercentage };
        })
        .sort(
          (a, b) => parseFloat(b.winPercentage) - parseFloat(a.winPercentage)
        );

      setSortedFriends(sorted);
    }
  }, [standings]);

  const toggleExpanded = (person) => {
    setExpanded((prev) => ({ ...prev, [person]: !prev[person] }));
  };

  return (
    <div className="app">
      <h1 className="header">Ball Knowers Only Standings (2024)</h1>
      <Navbar /> {/* Navbar displayed right under the title */}
      <button onClick={fetchStandings} className="refresh-button">
        Refresh Standings
      </button>
      {loading ? ( // Show loading text when fetching data
        <p>Loading standings...</p>
      ) : (
        <ul className="standings-list">
          {sortedFriends.map(
            ({ person, totalWins, totalLosses, winPercentage }) => (
              <li key={person} className="standings-item">
                <h2
                  onClick={() => toggleExpanded(person)}
                  className="friend-name"
                >
                  {person} | {totalWins}-{totalLosses} | {winPercentage}%
                </h2>
                {expanded[person] && (
                  <ul className="team-list">
                    {getTeamsForPerson(teamsPerPerson[person]).map(
                      (team, index) => (
                        <li key={index} className="team-item">
                          <img
                            src={team.team.logo}
                            alt={`${team.team.name} logo`}
                            className="team-logo"
                          />
                          {team.team.name} | Wins: {team.win.total} | Losses:{" "}
                          {team.loss.total}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </li>
            )
          )}
        </ul>
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home Route */}
          <Route path="/scoreboard" element={<Scoreboard />} />{" "}
          {/* Scoreboard Route */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
