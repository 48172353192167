import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar2"; // Import the Navbar component
import "./Scoreboard.css"; // Import Scoreboard styles

const Scoreboard = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      const options = {
        method: "GET",
        url: "https://api-nba-v1.p.rapidapi.com/games",
        params: { date: new Date().toISOString().split("T")[0] }, // Use current date
        headers: {
          "x-rapidapi-host": "api-nba-v1.p.rapidapi.com",
          "x-rapidapi-key":
            "c2ef872e3emsh085221d8c5b21f3p13749djsn5f472c57f22f",
        },
      };

      try {
        const response = await axios.request(options);
        setGames(response.data.response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching NBA games", error);
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  return (
    <div className="scoreboard-page">
      <h1 className="header">NBA Scoreboard - Today's Games</h1>
      <Navbar /> {/* Include the Navbar below the header */}
      {loading ? (
        <p>Loading games...</p>
      ) : (
        <div className="games-list">
          {games.length > 0 ? (
            games.map((game, index) => (
              <div key={index} className="game-item">
                <div className="teams">
                  <div className="team">
                    <img
                      src={game.teams.visitors.logo}
                      alt={`${game.teams.visitors.name} logo`}
                      className="team-logo"
                    />
                    <span>{game.teams.visitors.name}</span>
                  </div>
                  <div className="vs">vs</div>
                  <div className="team">
                    <img
                      src={game.teams.home.logo}
                      alt={`${game.teams.home.name} logo`}
                      className="team-logo"
                    />
                    <span>{game.teams.home.name}</span>
                  </div>
                </div>
                <div className="scores">
                  <span>{game.scores.visitors.points}</span> -{" "}
                  <span>{game.scores.home.points}</span>
                </div>
              </div>
            ))
          ) : (
            <p>No games available today.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Scoreboard;
